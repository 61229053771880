import React, { useState, useEffect, useRef } from 'react';
//import io from 'socket.io-client';
import './HorseRaceGame.css'; // Assuming your CSS is in this file
import './canon.css'
import Piano from './Pianos'
import gameAudio from './static/video_games.mp3';
import heyYoAudio from './static/herman_hey_yo.mp3';
import ReactGA from "react-ga4";


const defaultNames = ['Archie Luxury', 'Lemon Lemon Curly Curly', 'Mike The Weed Consultant', 'Bearrrr', 'mookie', 'Herpes Ingraham'];

const HorseRaceGame = () => {

    ReactGA.initialize("G-E7Q3DRDRVW");

    const [numHorses, setNumHorses] = useState(6);
    const [raceDuration, setRaceDuration] = useState(10);
    const [raceFinished, setRaceFinished] = useState(false);
    const [resultText, setResultText] = useState('Let\'s go!');  // Added result text state
    const [socket, setSocket] = useState(null);
    const raceInterval = useRef(null);  // Using ref for interval
    const [raceMusic, setRaceMusic] = useState(null);
    const [heyYoAudioSoundEffect, setHeyYoAudioSoundEffect] = useState(null);
    const [pianos, setPianos] = useState([]);
    const raceTrackRef = useRef(null);
    const horseWidth = 50;
    const horseHeight = 120;
    const [winner, setWinner] = useState(null);

    const [horses, setHorses] = useState(defaultNames.map((name, index) => ({
        name,
        position: 0,
        top: index * (horseHeight + 20), // Remove the offset
        left: 20,
        id: index,
        width: horseWidth
    })));

    const [raceTrackWidth, setRaceTrackWidth] = useState(0);

    const [isPaused, setIsPaused] = useState(false);


useEffect(() => {
  console.log('Loading audio');
  const music = new Audio(gameAudio); // Initialize the audio object with the imported file
  music.load(); // Load the audio file to prepare it for playing
  setRaceMusic(music); // Set the loaded audio object to your state

  const heyYo = new Audio(heyYoAudio);
  heyYo.load();
  setHeyYoAudioSoundEffect(heyYo);
}, []);

useEffect(() => {
  const handleResize = () => {
    if (raceTrackRef.current) {
      setRaceTrackWidth(raceTrackRef.current.offsetWidth);
    }
  };

  handleResize();  // Call it immediately in case the initial width is not set correctly

  window.addEventListener('resize', handleResize);
  return () => window.removeEventListener('resize', handleResize);
}, []); 



useEffect(() => {
    const gameLoop = setInterval(() => {
        if (!isPaused) {
            setPianos(prevPianos => {
                const updatedPianos = [];

                prevPianos.forEach(piano => {
                    const newTop = piano.top + 5;
                    updatedPianos.push({ ...piano, top: newTop });
                });

                return updatedPianos;
            });
        }
    }, 100);

    return () => clearInterval(gameLoop);
}, [isPaused]);

const togglePause = () => {
    setIsPaused(!isPaused);
    if (isPaused) {
        console.log("Game resumed");
        raceMusic?.play();
        raceInterval.current = setInterval(() => {
            setHorses(prevHorses => prevHorses.map((horse, index) => {
                // ...
            }));
        }, 100);
    } else {
        console.log("Game paused");
        console.log("Paused Piano Positions:", pianos.map(p => `Left: ${p.left}, Top: ${p.top}`));
        console.log("Paused Horse Positions:", horses.map(h => `Name: ${h.name}, Left: ${h.left}, Top: ${h.top}`));
        raceMusic?.pause();
        clearInterval(raceInterval.current);
    }
};

const dropPiano = () => {
    const left = Math.random() * (raceTrackWidth - 50);
    const firstHorseTop = horses[0].top; // Get the top position of the first horse
    const pianoTop = firstHorseTop; // Set the piano's top position above the first horse
    setPianos(prevPianos => [...prevPianos, { left, top: pianoTop, id: Math.random() }]);
};


useEffect(() => {
  if (raceTrackRef.current) {
    setRaceTrackWidth(raceTrackRef.current.offsetWidth);
  }

  // Optional: Handle window resize if necessary
  const handleResize = () => {
    if (raceTrackRef.current) {
      setRaceTrackWidth(raceTrackRef.current.offsetWidth);
    }
  };

  window.addEventListener('resize', handleResize);

  // Cleanup function to remove the event listener
  return () => window.removeEventListener('resize', handleResize);
}, []); 


const handlePianoHit = (pianoId) => {
    const pianoElement = document.querySelector(`.piano[data-id="${pianoId}"]`);
    if (!pianoElement) return false;

    const pianoRect = pianoElement.getBoundingClientRect();

    let hitHorseIndex = -1;

    horses.forEach((horse, index) => {
        const horseElement = document.querySelector(`.horse[data-id="${horse.id}"]`);
        if (horseElement) {
            const horseRect = horseElement.getBoundingClientRect();

            if (!(pianoRect.right < horseRect.left ||
                pianoRect.left > horseRect.right ||
                pianoRect.bottom < horseRect.top ||
                pianoRect.top > horseRect.bottom)) {
                hitHorseIndex = index;
                
                // Update the piano's left position to align with the horse
                setPianos((prevPianos) =>
                    prevPianos.map((piano) =>
                        piano.id === pianoId ? { ...piano, left: horse.left } : piano
                    )
                );

                // Log the collision detection
                console.log(`Collision detected`);

                // Play the sound effect
                heyYoAudioSoundEffect.volume = 0.2;
                heyYoAudioSoundEffect?.play().catch(err => console.error("Error playing the sound:", err));
            }
        }
    });

    if (hitHorseIndex !== -1) {
        setHorses((prevHorses) =>
            prevHorses.filter((_, index) => index !== hitHorseIndex)
        );

        setPianos((prevPianos) =>
            prevPianos.filter((piano) => piano.id !== pianoId)
        );

        return true;
    }

    return false;
};



  const clearPianos = () => {
    setPianos([]);
  };

/*  useEffect(() => {
    const newSocket = io('http://127.0.0.1:8000');
    setSocket(newSocket);
    console.log('websocket connected?')
    return () => {
      newSocket.disconnect();
    };
  }, []);*/

  
const muteMusic = () => {
  if (raceMusic && !raceMusic.paused) {
    raceMusic.pause();
  } else {
    raceMusic.volume = 0.2;
    raceMusic?.play().catch(err => console.error("Error playing the sound:", err));
  }
};

  const resetRace = () => {
    clearInterval(raceInterval.current);
    clearInterval(pianoDropIntervalRef.current);
setHorses(defaultNames.map((name, index) => ({
  name,
  position: 0, // Initial horizontal position
  id: index,
  top: index * (horseHeight + 20),
  left: 0, // Initial left position, should be the same as the initial position
  speed: Math.random() * 5 + 2
})));
    setRaceFinished(false);
    raceMusic.pause();
    raceMusic.currentTime = 0;
    clearInterval(raceInterval.current);  // Clear interval on race reset
  };

  const pianoDropIntervalRef = useRef(null);

const startRace = () => {
    // Clear any existing intervals to prevent overlaps
    clearInterval(raceInterval.current);
    clearInterval(pianoDropIntervalRef.current);

    // Reset the state for a new race
    setRaceFinished(false);
    setResultText('Let\'s go!');
    clearPianos();  // Ensure pianos are cleared before starting a new race

    raceMusic.volume = 0.2;
    raceMusic?.play().catch(err => console.error("Error playing the sound:", err));

    const startRaceData = {
        timestamp: new Date().toISOString(),
    };
    //socket.emit('startedRace', startRaceData);
    console.log('sent websocket race start indicator with payload:', startRaceData);

    setHorses(defaultNames.map((name, index) => ({
        name,
        position: 0,
        id: index,
        top: index * (horseHeight + 20),
        speed: Math.random() * 5 + 2
    })));

    raceInterval.current = setInterval(() => {
    if (!isPaused) { // Only update horse positions if not paused
        setHorses(prevHorses => prevHorses.map((horse, index) => {
            let speedVariation = Math.random() - 0.5;
            let randomSpeedChange = Math.random() < 0.5 ? (Math.random() * 1.25 - 1) * 3 : 0;
            let newPosition = horse.position + horse.speed + speedVariation + randomSpeedChange;

            const racetrack = document.querySelector('.racetrack');
            const finishLine = racetrack ? racetrack.offsetWidth - 100 : 1000;

        if (newPosition >= finishLine && !raceFinished) {
            setRaceFinished(true);
            setWinner(horse);
            setResultText(`${horse.name} wins the race!`);
            clearInterval(raceInterval.current);
            clearInterval(pianoDropIntervalRef.current); // Stop dropping pianos
            setPianos([]); // Clear existing pianos from the racetrack
            raceMusic?.pause(); // Pause the race music when the race is finished

            const raceEndTime = new Date().toISOString();
            //socket.emit('raceResult', { winner: horse.name, end_time: raceEndTime });
            console.log('Race finished. Winner:', horse.name);
        }

            return { ...horse, position: newPosition, left: newPosition };
        }));
    }
    }, 100);

    pianoDropIntervalRef.current = setInterval(() => {
        if (!isPaused && !raceFinished) {
            dropPiano();
        } else {
            clearInterval(pianoDropIntervalRef.current); // Ensure we stop dropping pianos when the race is finished
        }
    }, 5000);
};


  return (
    <div>
        <div className="centered-text">
            <br />
            <h1 className="centered-text">mookosa.co</h1>
            <br />
            <h3 className="centered-text">A Four-Pillar Luxury Experience</h3>
            <h4 className="centered-text">for degenerates, by degenerates</h4>
            <h4 className="centered-text">coming soon.</h4>
            <a href="https://www.paypal.com/donate/?hosted_button_id=ZRYLK7GGSLT78" target="_blank" rel="noopener noreferrer">
          send money now
        </a>
    </div>

    <div className="raceControls">
{/*      <label>
        Number of Horses (max 6):
        <input type="number" value={numHorses} onChange={(e) => setNumHorses(parseInt(e.target.value, 10))} min="1" max="6" />
      </label><br /><br />

      <label>
        Race Duration (seconds, max 30):
        <input type="number" value={raceDuration} onChange={(e) => setRaceDuration(parseInt(e.target.value, 10))} min="1" max="30" />
      </label><br /><br />
*/}

      <div>
        <button onClick={startRace} style={{ color: 'white', backgroundColor: 'green', marginRight: '10px' }}>go</button>
        <button onClick={resetRace} style={{ color: 'white', backgroundColor: 'green', marginRight: '10px' }}>reset</button>
        <button onClick={muteMusic} style={{color: 'white', backgroundColor: 'green', }}>mute</button>
            {/* <button onClick={togglePause} style={{ color: 'white', backgroundColor: 'red' }}>
                {isPaused ? 'Resume' : 'Pause'} 
            </button> */}
      </div>


      </div>

<div className="racetrack" ref={raceTrackRef}>
    {horses.map((horse, index) => (
        <div key={index} className="horse" data-id={horse.id} style={{ 
            left: `${horse.position}px`, 
            top: `${horse.top}px` 
        }}>
            <div className="caption">{horse.name}</div>
        </div>
    ))}
{pianos.map((piano) => (
    <Piano
        key={piano.id}
        id={piano.id}
        left={piano.left}
        onHit={handlePianoHit}
    />
))}
</div>
      
        {raceFinished && winner && (
          <>
            <img src="./static/canon.png" alt="Cannon" className="cannon" />
            <div className={`horse horse-animate`} style={{
              backgroundImage: './static/horse_herman1.png' 
            }}>
              <div className="caption">{winner.name} WINNER</div>

            </div>
          </>
        )}

    </div>
  );
};

export default HorseRaceGame;
