import React, { useState, useEffect } from 'react';

const Piano = ({ left, id, onHit }) => {
    const [top, setTop] = useState(-100);

    useEffect(() => {
        const interval = setInterval(() => {
            setTop((prevTop) => {
                if (prevTop > window.innerHeight) {
                    clearInterval(interval);
                } else {
                    onHit(id); // Call the onHit function with the piano's id
                    return prevTop + 5;
                }
            });
        }, 50);

        return () => clearInterval(interval);
    }, [id, onHit]);

    return (
        <div className="piano" data-id={id} style={{ left: `${left}px`, top: `${top}px` }} />
    );
};

export default Piano;